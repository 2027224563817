import { jwtDecode } from "jwt-decode";
class Auth {
    #TOKEN_KEY = 'token';
    #TOKEN_EXPIRATION_KEY = 'tokenExpirationTimeStamp';

    constructor() {
        this.init();
    }

    init() {
        window.addEventListener('storage', this.handleStorageChange.bind(this));
    }

    handleStorageChange(event) {
        if (event.key === this.#TOKEN_KEY || event.key === this.#TOKEN_EXPIRATION_KEY) {
            this.isAuthenticated();
        }
    }

    isJWT(token) {
        if (typeof token !== 'string') return false;
        const parts = token.split('.');
        return parts.length === 3 && parts.every(part => part.length > 0);
    }

    isValidToken(token, tokenExpiration) {
        if (!this.isJWT(token)) {
            console.warn('Token does not follow JWT structure.');
            return false;
        }

        try {
            const decoded = jwtDecode(token);

            const currentTimeMs = Date.now();
            const tokenExpiryTimeMs = decoded.exp * 1000;

            if (tokenExpiryTimeMs && tokenExpiryTimeMs < currentTimeMs) {
                console.warn('Token has expired based on payload.');
                return false;
            }

            const expirationTimeMs = new Date(tokenExpiration).getTime();
            if (isNaN(expirationTimeMs)) {
                console.warn('Invalid token expiration timestamp.');
                return false;
            }

            if (Date.now() >= expirationTimeMs) {
                console.warn('Token has expired based on stored expiration timestamp.');
                return false;
            }

            return true;

        } catch (error) {
            console.error('Error validating token:', error);
            return false;
        }
    }

    getToken() {
        return localStorage.getItem(this.#TOKEN_KEY);
    }

    getTokenExpiration() {
        return localStorage.getItem(this.#TOKEN_EXPIRATION_KEY);
    }

    isAuthenticated() {
        const token = this.getToken();
        const tokenExpiration = this.getTokenExpiration();

        if (!token || !tokenExpiration || !this.isValidToken(token, tokenExpiration)) {
            this.logout((error) => !error);
            return false;
        }

        return true;
    }

    login(token, expirationTimestamp, cb) {
        if (!token || !expirationTimestamp) {
            console.error('Token or expiration timestamp is missing.');
            cb(new Error('Token or expiration timestamp is missing.'));
            return;
        }

        if (this.isValidToken(token, expirationTimestamp)) {
            try {
                localStorage.setItem(this.#TOKEN_KEY, token);
                localStorage.setItem(this.#TOKEN_EXPIRATION_KEY, expirationTimestamp);
                cb(null);
            } catch (error) {
                console.error('Error saving token to localStorage:', error);
                cb(error);
            }
        } else {
            console.warn('Invalid token or token has expired.');
            cb(new Error('Invalid token or token has expired.'));
        }
    }

    logout(cb) {
        try {
            localStorage.clear();
            cb(null);
        } catch (error) {
            console.error('Error during logout:', error);
            cb(error);
        }
    }
}

const authInstance = new Auth();
export default authInstance;