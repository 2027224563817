export const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const LATEST_COMMIT_ID = process.env.REACT_APP_LATEST_COMMIT_ID;

export const EMAIL_AUTH = process.env.REACT_APP_EMAIL_AUTH;

export const RESET_PASSWORD = process.env.REACT_APP_RESET_PASSWORD;
export const CHANGE_PASSWORD = process.env.REACT_APP_CHANGE_PASSWORD;
export const VERIFY_RESET_PASSWORD_TOKEN = process.env.REACT_APP_VERIFY_RESET_PASSWORD_TOKEN

export const APP_INSIGHTS_KEY = process.env.REACT_APP_APP_INSIGHTS_KEY

export const GET_CART_ORDERS_FOR_DAY = process.env.REACT_APP_GET_CART_ORDERS_FOR_DAY
export const GET_CART_ORDER = process.env.REACT_APP_GET_CART_ORDER
export const CREATE_CART_ORDER = process.env.REACT_APP_CREATE_CART_ORDER
export const UPDATE_CART_ORDER = process.env.REACT_APP_UPDATE_CART_ORDER
export const DELETE_CART_ORDER = process.env.REACT_APP_DELETE_CART_ORDER
export const GET_CART_ORDER_PRODUCTS = process.env.REACT_APP_GET_CART_ORDER_PRODUCTS
export const UPDATE_PURCHASE_ORDER_FOR_CART_ORDER = process.env.REACT_APP_UPDATE_PURCHASE_ORDER_FOR_CART_ORDER

export const GET_CART_ORDER_CALENDAR = process.env.REACT_APP_GET_CART_ORDER_CALENDAR
export const GET_DELIVERY_ORDER_CALENDAR = process.env.REACT_APP_GET_DELIVERY_ORDER_CALENDAR
export const GET_ORDERS = process.env.REACT_APP_GET_ORDERS
export const GET_ORDERS_EDITOR_DATA = process.env.REACT_APP_GET_ORDERS_EDITOR_DATA
export const SAVE_ORDERS_EDITOR_DATA = process.env.REACT_APP_SAVE_ORDERS_EDITOR_DATA

export const GET_CUSTOMER_NOTIFICATIONS = process.env.REACT_APP_GET_CUSTOMER_NOTIFICATIONS
export const POST_CUSTOMER_NOTIFICATIONS = process.env.REACT_APP_POST_CUSTOMER_NOTIFICATIONS
export const GET_CUSTOMER_NOTIFICATIONS_FOR_LOCATION = process.env.REACT_APP_GET_CUSTOMER_NOTIFICATIONS_FOR_LOCATION

export const GET_MAGIC_LINK = process.env.REACT_APP_GET_MAGIC_LINK

export const GET_USERS = process.env.REACT_APP_GET_USERS;
export const ALL_USER_ROLES = process.env.REACT_APP_ALL_USER_ROLES_URL;
export const AVAILABLE_ASSIGN_USER_ROLES = process.env.REACT_APP_AVAILABLE_ASSIGN_USER_ROLES_URL;
export const EXISTING_EFFECTIVE_USER_ROLES = process.env.REACT_APP_EXISTING_EFFECTIVE_USER_ROLES_URL;
export const ADMIN_EFFECTIVE_USER_ROLES = process.env.REACT_APP_ADMIN_EFFECTIVE_USER_ROLES_URL;
export const SCOPE_EFFECTIVE_USER_ROLES = process.env.REACT_APP_SCOPE_EFFECTIVE_USER_ROLES_URL
export const ADD_NEW_USER_ROLE = process.env.REACT_APP_ADD_NEW_USER_ROLES_URL
export const DELETE_USER_ROLE = process.env.REACT_APP_DELETE_USER_ROLE_URL;
export const DELETE_ADMIN_USER_ROLE = process.env.REACT_APP_DELETE_ADMIN_USER_ROLE_URL;
export const GET_PICKERS_URL = process.env.REACT_APP_GET_PICKERS_URL
export const GET_DRIVERS_URL = process.env.REACT_APP_GET_DRIVERS_URL
export const GET_LOCATION_FOR_USER = process.env.REACT_APP_GET_LOCATION_FOR_USER
export const GET_LOCATION_FOR_USER_AND_ROLE = process.env.REACT_APP_GET_LOCATION_FOR_USER_AND_ROLE
export const GET_WAREHOUSES_FOR_USER = process.env.REACT_APP_GET_WAREHOUSES_FOR_USER

export const WAREHOUSE_URL = process.env.REACT_APP_WAREHOUSE_URL;
export const WAREHOUSE_CUSTOMER_DELIVERY_URL = process.env.REACT_APP_WAREHOUSE_CUSTOMER_DELIVERY_URL
// export const GET_OTHER_ELIGIBLE_VENDOR_DELIVERY_DAYS_FOR_JIT_PRODUCTS = process.env.REACT_APP_GET_OTHER_ELIGIBLE_VENDOR_DELIVERY_DAYS_FOR_JIT_PRODUCTS

export const GET_TRANSFERRED_PRODUCTS_CUTOFF_REPORT = process.env.REACT_APP_GET_TRANSFERRED_PRODUCTS_CUTOFF_REPORT
export const GET_INVENTORY_PRODUCTS_CUTOFF_REPORT = process.env.REACT_APP_GET_INVENTORY_PRODUCTS_CUTOFF_REPORT
export const GET_JIT_PRODUCTS_CUTOFF_REPORT = process.env.REACT_APP_GET_JIT_PRODUCTS_CUTOFF_REPORT
export const GET_WAREHOUSE_CUTOFF_REPORT = process.env.REACT_APP_GET_WAREHOUSE_CUTOFF_REPORT
export const GET_SOURCE_WAREHOUSE_TRANSFERRED_PRODUCTS_CUTOFF_RECORD = process.env.REACT_APP_GET_SOURCE_WAREHOUSE_TRANSFERRED_PRODUCTS_CUTOFF_RECORD

export const WAREHOUSE_ROUTES = process.env.REACT_APP_WAREHOUSE_ROUTES;
export const GET_ROUTE_CODES_FOR_LOCATION = process.env.REACT_APP_GET_ROUTE_CODES_FOR_LOCATION
export const WAREHOUSE_ROUTED_LOCATION = process.env.REACT_APP_WAREHOUSE_ROUTED_LOCATION
export const WAREHOUSE_ROUTES_LOCATION = process.env.REACT_APP_WAREHOUSE_ROUTES_LOCATION
export const WAREHOUSE_UNROUTED_LOCATION = process.env.REACT_APP_WAREHOUSE_UNROUTED_LOCATION
export const ROUTES_FOR_LOCATION = process.env.REACT_APP_ROUTES_FOR_LOCATION

export const INTER_WAREHOUSE_ROUTES = process.env.REACT_APP_INTER_WAREHOUSE_ROUTES
export const GET_INTER_WAREHOUSE_ROUTE_BY_ID = process.env.REACT_APP_GET_INTER_WAREHOUSE_ROUTE_BY_ID
export const ADD_INTER_WAREHOUSE_ROUTES = process.env.REACT_APP_ADD_INTER_WAREHOUSE_ROUTES
export const UPDATE_INTER_WAREHOUSE_ROUTES = process.env.REACT_APP_UPDATE_INTER_WAREHOUSE_ROUTES
export const DELETE_INTER_WAREHOUSE_ROUTES = process.env.REACT_APP_DELETE_INTER_WAREHOUSE_ROUTES
export const GET_ELIGIBLE_TRANSFERRED_PRODUCTS = process.env.REACT_APP_GET_ELIGIBLE_TRANSFERRED_PRODUCTS
export const GET_ELIGIBLE_TRANSFERRED_PRODUCTS_MATRIX = process.env.REACT_APP_GET_ELIGIBLE_TRANSFERRED_PRODUCTS_MATRIX

export const INTER_WAREHOUSE_TRANSFERRED_PRODUCTS = process.env.REACT_APP_INTER_WAREHOUSE_TRANSFERRED_PRODUCTS
export const APP_ADD_INTER_WAREHOUSE_TRANSFERRED_PRODUCTS = process.env.REACT_APP_ADD_INTER_WAREHOUSE_TRANSFERRED_PRODUCTS
export const UPDATE_INTER_WAREHOUSE_TRANSFERRED_PRODUCTS = process.env.REACT_APP_UPDATE_INTER_WAREHOUSE_TRANSFERRED_PRODUCTS
export const DELETE_INTER_WAREHOUSE_TRANSFERRED_PRODUCTS = process.env.REACT_APP_DELETE_INTER_WAREHOUSE_TRANSFERRED_PRODUCTS
export const SWITCH_TO_LOCAL_VENDOR = process.env.REACT_APP_SWITCH_TO_LOCAL_VENDOR
export const IMPORT_TRANSFERRED_PRODUCT_CSV_FILE = process.env.REACT_APP_IMPORT_TRANSFERRED_PRODUCT_CSV_FILE
export const DOWNLOAD_TRANSFERRED_PRODUCT_CSV_FILE = process.env.REACT_APP_DOWNLOAD_TRANSFERRED_PRODUCT_CSV_FILE

export const GET_TRANSFERRED_PRODUCTS_CUSTOMER_CUTOFFS_DATA_TABLE = process.env.REACT_APP_GET_TRANSFERRED_PRODUCTS_CUSTOMER_CUTOFFS_DATA_TABLE
export const GET_TRANSFERRED_PRODUCTS_CUSTOMER_CUTOFFS_DATA_DETAILS = process.env.REACT_APP_GET_TRANSFERRED_PRODUCTS_CUSTOMER_CUTOFFS_DATA_DETAILS
export const GET_CUSTOMER_CUTOFFS_DETAILS_FOR_TRANSFERRED_PRODUCT = process.env.REACT_APP_GET_CUSTOMER_CUTOFFS_DETAILS_FOR_TRANSFERRED_PRODUCT

export const ORDER_TEMPLATE = process.env.REACT_APP_ORDER_TEMPLATE
export const ORDER_TEMPLATE_LOCATION = process.env.REACT_APP_ORDER_TEMPLATE_LOCATION
export const GET_ORDER_TEMPLATE_FOR_LOCATION = process.env.REACT_APP_GET_ORDER_TEMPLATE_FOR_LOCATION
export const ORDER_TEMPLATE_PRODUCTS = process.env.REACT_APP_ORDER_TEMPLATE_PRODUCTS

export const PRICING_TEMPLATE = process.env.REACT_APP_PRICING_TEMPLATE
export const PRICING_TEMPLATE_LOCATION = process.env.REACT_APP_PRICING_TEMPLATE_LOCATION
export const GET_PRICING_TEMPLATE_FOR_LOCATION = process.env.REACT_APP_GET_PRICING_TEMPLATE_FOR_LOCATION
export const PRICING_TEMPLATE_PRODUCTS = process.env.REACT_APP_PRICING_TEMPLATE_PRODUCTS
export const ADD_PRODUCTS_BY_CATEGORY = process.env.REACT_APP_ADD_PRODUCT_BY_CATEGORY_TO_PRICING_TEMPLATE_PRODUCTS
export const ADD_PRODUCTS_BY_VENDOR = process.env.REACT_APP_ADD_PRODUCT_BY_VENDOR_TO_PRICING_TEMPLATE_PRODUCTS

export const DELIVERY_MANIFESTS_DATE_RANGE = process.env.REACT_APP_DELIVERY_MANIFESTS_DATE_RANGE
export const DELIVERY_MANIFESTS_UPDATE = process.env.REACT_APP_DELIVERY_MANIFESTS_UPDATE
export const CREATE_DELIVERY_MANIFEST = process.env.REACT_APP_CREATE_DELIVERY_MANIFEST

export const DELIVERY_ROUTES = process.env.REACT_APP_DELIVERY_ROUTES
export const DELIVERY_ROUTES_BY_ID = process.env.REACT_APP_DELIVERY_ROUTES_BY_ID
export const DELIVERY_ROUTES_UPDATE = process.env.REACT_APP_DELIVERY_ROUTES_UPDATE
export const DRIVER_NOTES = process.env.REACT_APP_DRIVER_NOTES
export const CREATE_ADHOC_ROUTE = process.env.REACT_APP_CREATE_ADHOC_ROUTE
export const UPDATE_DELIVERY_ROUTES_SEQ = process.env.REACT_APP_UPDATE_DELIVERY_ROUTES_SEQ
export const UPDATE_DRIVER_NOTES = process.env.REACT_APP_UPDATE_DRIVER_NOTES

export const GET_ALL_DELIVERY_ORDERS_FOR_DAY = process.env.REACT_APP_GET_ALL_DELIVERY_ORDERS_FOR_DAY
export const DELIVERY_ORDERS = process.env.REACT_APP_DELIVERY_ORDERS
export const INVOICE_NOTES = process.env.REACT_APP_INVOICE_NOTES_FOR_CUSTOMER
export const DELIVERY_ORDERS_BY_ID = process.env.REACT_APP_DELIVERY_ORDERS_BY_ID
export const UPDATE_ORDER_SEQ_WITHIN_ROUTE = process.env.REACT_APP_UPDATE_ORDER_SEQ_WITHIN_ROUTE
export const UPDATE_INVOICE_NOTES = process.env.REACT_APP_UPDATE_INVOICE_NOTES_FOR_CUSTOMER
export const UPDATE_ORDER_AFTER_CUTOFF = process.env.REACT_APP_UPDATE_ORDER_AFTER_CUTOFF
export const GET_DELIVERY_ORDER_PRODUCTS = process.env.REACT_APP_GET_DELIVERY_ORDER_PRODUCTS
export const EDIT_DELIVERY_ORDER_PRODUCTS = process.env.REACT_APP_EDIT_DELIVERY_ORDER_PRODUCTS
export const UPDATE_PRODUCTS_TEMP_AT_PICKUP = process.env.REACT_APP_UPDATE_PRODUCTS_TEMP_AT_PICKUP
export const UPDATE_PRODUCTS_TEMP_AT_DELIVERY = process.env.REACT_APP_UPDATE_PRODUCTS_TEMP_AT_DELIVERY
export const CREATE_SUPPLEMENTAL_ORDER_AFTER_CUTOFF = process.env.REACT_APP_CREATE_SUPPLEMENTAL_ORDER_AFTER_CUTOFF

export const CREATE_TASK_FOR_PRINT_ALL_ROUTES_INVOICES = process.env.REACT_APP_CREATE_TASK_FOR_PRINT_ALL_ROUTES_INVOICES
export const CREATE_TASK_FOR_PRINT_ROUTE_INVOICES = process.env.REACT_APP_CREATE_TASK_FOR_PRINT_ROUTE_INVOICES
export const GET_INVOICES_COUNT_AVAILABLE_FOR_DAY = process.env.REACT_APP_GET_INVOICES_COUNT_AVAILABLE_FOR_DAY
export const GET_INVOICES_COUNT_AVAILABLE_FOR_ROUTE = process.env.REACT_APP_GET_INVOICES_COUNT_AVAILABLE_FOR_ROUTE
export const GET_FILE_URL_TO_PRINT = process.env.REACT_APP_GET_FILE_URL_TO_PRINT
export const GENERATE_START_PDF_PAGE_FOR_PRINT = process.env.REACT_APP_GENERATE_START_PDF_PAGE_FOR_PRINT
export const GENERATE_END_PDF_PAGE_FOR_PRINT = process.env.REACT_APP_GENERATE_END_PDF_PAGE_FOR_PRINT
export const GET_PDF_DOCUMENT = process.env.REACT_APP_GET_PDF_DOCUMENT
export const GET_PRODUCTS_ON_THE_INTER_WAREHOUSE_ROUTE = process.env.REACT_APP_GET_PRODUCTS_ON_THE_INTER_WAREHOUSE_ROUTE
export const GET_ALL_ROUTE_AND_BOX_CODES_FOR_DELIVERY_ORDERS_NOT_STAGED_ON_ROUTE_FOR_A_DAY = process.env.REACT_APP_GET_ALL_ROUTE_AND_BOX_CODES_FOR_DELIVERY_ORDERS_NOT_STAGED_ON_ROUTE_FOR_A_DAY
export const GET_BOX_CODES_FOR_DELIVERY_ORDERS_NOT_STAGED_ON_ROUTE_FOR_A_DAY = process.env.REACT_APP_GET_BOX_CODES_FOR_DELIVERY_ORDERS_NOT_STAGED_ON_ROUTE_FOR_A_DAY

export const MY_DELIVERIES = process.env.REACT_APP_MY_DELIVERIES
export const GET_MY_DELIVERY_ROUTES = process.env.REACT_APP_GET_MY_DELIVERY_ROUTES
export const GET_DELIVERY_DETAILS = process.env.REACT_APP_GET_DELIVERY_DETAILS
export const GET_DRIVER_REPORT = process.env.REACT_APP_GET_DRIVER_REPORT
export const GET_DELIVERY_PHOTOS_SAS_TOKEN = process.env.REACT_APP_GET_DELIVERY_PHOTOS_SAS_TOKEN
export const ASSIGN_DELIVERY_DRIVER = process.env.REACT_APP_ASSIGN_DELIVERY_DRIVER
export const UNDO_ARRIVED_FOR_DELIVERY = process.env.REACT_APP_UNDO_ARRIVED_FOR_DELIVERY
export const MARK_ARRIVED_FOR_DELIVERY = process.env.REACT_APP_MARK_ARRIVED_FOR_DELIVERY
export const MARK_DELIVERED_SUCCESSFULLY = process.env.REACT_APP_MARK_DELIVERED_SUCCESSFULLY
export const MARK_FAILED_DELIVERY = process.env.REACT_APP_MARK_FAILED_DELIVERY
export const LEFT_WAREHOUSE = process.env.REACT_APP_LEFT_WAREHOUSE
export const BACK_AT_WAREHOUSE = process.env.REACT_APP_BACK_AT_WAREHOUSE
export const FAIL_DELIVERY = process.env.REACT_APP_FAIL_DELIVERY
export const CHANGE_ROUTE = process.env.REACT_APP_CHANGE_ROUTE
export const RESCHEDULE_DELIVERY = process.env.REACT_APP_RESCHEDULE_DELIVERY

export const GET_MY_PICKS_FOR_DELIVERY_DATE = process.env.REACT_APP_GET_MY_PICKS_FOR_DELIVERY_DATE
export const GET_PICKING_ORDERS_FOR_DELIVERY_DATE = process.env.REACT_APP_GET_PICKING_ORDERS_FOR_DELIVERY_DATE
export const GET_PICKING_ORDER_DETAILS = process.env.REACT_APP_GET_PICKING_ORDER_DETAILS
export const GET_PICKING_ORDER_PRODUCTS_FOR_PICKER = process.env.REACT_APP_GET_PICKING_ORDER_PRODUCTS_FOR_PICKER
export const GET_NEXT_PICKING_ORDER_SEQUENCE_FOR_PICK_TYPE_WITHIN_ROUTE = process.env.REACT_APP_GET_NEXT_PICKING_ORDER_SEQUENCE_FOR_PICK_TYPE_WITHIN_ROUTE
export const IS_PICKING_STARTED = process.env.REACT_APP_IS_PICKING_STARTED
export const SET_TIME_PICKER_STARTED = process.env.REACT_APP_SET_TIME_PICKER_STARTED
export const SET_PICKED_QUANTITY = process.env.REACT_APP_SET_PICKED_QUANTITY
export const UNDO_PICKED_QUANTITY = process.env.REACT_APP_UNDO_PICKED_QUANTITY
export const MARK_STAGED_ROUTE = process.env.REACT_APP_MARK_STAGED_ROUTE
export const ASSIGN_PICKER_FOR_PICKING_ORDER = process.env.REACT_APP_ASSIGN_PICKER_FOR_PICKING_ORDER
export const ASSIGN_NEXT_ORDER = process.env.REACT_APP_ASSIGN_NEXT_ORDER_TO_PICKER
export const UPDATE_PICKING_ORDER_SEQ_WITHIN_ROUTE = process.env.REACT_APP_UPDATE_PICKING_ORDER_SEQ_WITHIN_ROUTE

export const CATEGORIES_URL = process.env.REACT_APP_CATEGORIES_URL;
export const GET_PRODUCTS_BY_CATEGORIES = process.env.REACT_APP_GET_PRODUCTS_BY_CATEGORIES

export const CUSTOMER_LOCATION_URL = process.env.REACT_APP_CUSTOMER_LOCATION_URL;
export const CUSTOMER_LOCATION_BY_ID = process.env.REACT_APP_CUSTOMER_LOCATION_BY_ID
export const HUB_SPOT_CUSTOMER_LOCATION = process.env.REACT_APP_HUB_SPOT_CUSTOMER_LOCATION
export const GET_USERS_FOR_LOCATION = process.env.REACT_APP_GET_USERS_FOR_LOCATION

export const GET_PLACED_ORDERS_FOR_ALL_LOCATION = process.env.REACT_APP_GET_PLACED_ORDERS_FOR_ALL_LOCATION
export const GET_PLACED_ORDERS_FOR_LOCATION = process.env.REACT_APP_GET_PLACED_ORDERS_FOR_LOCATION
export const GET_PLACED_ORDERS_FOR_PRODUCT = process.env.REACT_APP_GET_PLACED_ORDERS_FOR_PRODUCT
export const GET_FORECAST_ORDERS_FOR_ALL_LOCATION = process.env.REACT_APP_GET_FORECAST_ORDERS_FOR_ALL_LOCATION
export const GET_FORECAST_ORDERS_FOR_LOCATION = process.env.REACT_APP_GET_FORECAST_ORDERS_FOR_LOCATION
export const GET_FORECAST_ORDERS_FOR_PRODUCT = process.env.REACT_APP_GET_FORECAST_ORDERS_FOR_PRODUCT

export const VENDORS_URL = process.env.REACT_APP_VENDORS_URL;
export const GET_VENDOR_BY_ID = process.env.REACT_APP_GET_VENDOR_BY_ID
export const GET_VENDORS_TO_WAREHOUSE_DATA = process.env.REACT_APP_GET_VENDORS_TO_WAREHOUSE;
export const AVAILABLE_WAREHOUSE_FOR_VENDORS = process.env.REACT_APP_AVAILABLE_WAREHOUSE_FOR_VENDORS;
export const ADD_VENDORS_TO_WAREHOUSE = process.env.REACT_APP_ADD_VENDORS_TO_WAREHOUSE
export const UPDATE_VENDORS_TO_WAREHOUSE = process.env.REACT_APP_UPDATE_VENDORS_TO_WAREHOUSE
export const DELETE_VENDORS_TO_WAREHOUSE = process.env.REACT_APP_DELETE_VENDORS_TO_WAREHOUSE

export const GET_VENDOR_PLACED_ORDERS = process.env.REACT_APP_GET_VENDOR_PLACED_ORDERS
export const GET_VENDOR_PLACED_ORDERS_BY_ID = process.env.REACT_APP_GET_VENDOR_PLACED_ORDERS_BY_ID
export const GET_VENDOR_STANDING_ORDERS = process.env.REACT_APP_GET_VENDOR_STANDING_ORDERS
export const GET_VENDOR_STANDING_ORDERS_BY_ID = process.env.REACT_APP_GET_VENDOR_STANDING_ORDERS_BY_ID

export const PRODUCT_PACKAGING_URL = process.env.REACT_APP_PRODUCT_PACKAGING_URL;

export const GET_VENDORS_TO_PRODUCT_DATA = process.env.REACT_APP_GET_VENDORS_TO_PRODUCT
export const GET_ACTIVE_VENDORS_PRODUCT_COUNT = process.env.REACT_APP_GET_ACTIVE_VENDORS_PRODUCT_COUNT
export const AVAILABLE_INVENTORY_OR_JIT_PRODUCTS_FOR_VENDORS = process.env.REACT_APP_AVAILABLE_INVENTORY_OR_JIT_PRODUCTS_FOR_VENDORS;
export const VENDORS_FOR_INVENTORY_OR_JIT_PRODUCT = process.env.REACT_APP_VENDORS_FOR_INVENTORY_OR_JIT_PRODUCT
export const GET_PRODUCTS_BY_VENDORS = process.env.REACT_APP_GET_PRODUCTS_BY_VENDORS
export const ADD_VENDORS_TO_PRODUCT = process.env.REACT_APP_ADD_VENDORS_TO_PRODUCT
export const UPDATE_VENDORS_TO_PRODUCT = process.env.REACT_APP_UPDATE_VENDORS_TO_PRODUCT
export const DELETE_VENDORS_TO_PRODUCT = process.env.REACT_APP_DELETE_VENDORS_TO_PRODUCT
export const ARCHIVE_ACTIVE_VND_PRODUCTS_FOR_WAREHOUSE = process.env.REACT_APP_ARCHIVE_ACTIVE_VENDOR_PRODUCTS_FOR_WAREHOUSE;
export const IMPORT_VENDORS_TO_PRODUCT_CSV_FILE = process.env.REACT_APP_IMPORT_VENDORS_TO_PRODUCT_CSV_FILE;
export const DOWNLOAD_VENDORS_TO_PRODUCT_CSV_FILE = process.env.REACT_APP_DOWNLOAD_VENDORS_TO_PRODUCT_CSV_FILE;

export const PRODUCTS_URL = process.env.REACT_APP_PRODUCTS_URL;
export const SEARCH_SOLD_PRODUCTS_FOR_STANDING_ORDER = process.env.REACT_APP_SEARCH_SOLD_PRODUCTS_FOR_STANDING_ORDER
export const SEARCH_SOLD_PRODUCTS_FOR_CART_ORDER = process.env.REACT_APP_SEARCH_SOLD_PRODUCTS_FOR_CART_ORDER
export const IMPORT_SOLD_CSV_FILE = process.env.REACT_APP_IMPORT_SOLD_PRODUCTS_CSV_FILE;
export const DOWNLOAD_SOLD_CSV_FILE = process.env.REACT_APP_DOWNLOAD_SOLD_PRODUCTS_CSV_FILE;
export const SOLD_PRODUCTS_BARCODE = process.env.REACT_APP_SOLD_PRODUCTS_BARCODE;
export const UPDATE_AVAILABILITY_FOR_PRODUCT = process.env.REACT_APP_UPDATE_AVAILABILITY_FOR_PRODUCT_URL
export const GET_CUSTOMER_ORDER_CUTOFF_DETAILS_FOR_PRODUCT_AND_WEEK = process.env.REACT_APP_GET_CUSTOMER_ORDER_CUTOFF_DETAILS_FOR_PRODUCT_AND_WEEK
export const GET_CUSTOMER_ORDER_CUTOFF_DETAILS_FOR_PRODUCT_AND_DAY = process.env.REACT_APP_GET_CUSTOMER_ORDER_CUTOFF_DETAILS_FOR_PRODUCT_AND_DAY
export const GET_JIT_SOLD_PRODUCTS_CUSTOMER_CUTOFF_TABLE = process.env.REACT_APP_GET_JIT_SOLD_PRODUCTS_CUSTOMER_CUTOFF_TABLE
export const GET_JIT_SOLD_PRODUCTS_CUSTOMER_CUTOFF_DETAILS = process.env.REACT_APP_GET_JIT_SOLD_PRODUCTS_CUSTOMER_CUTOFF_DETAILS
export const GET_CUSTOMER_CUTOFF_DETAILS_FOR_JIT_SOLD_PRODUCT = process.env.REACT_APP_GET_CUSTOMER_CUTOFF_DETAILS_FOR_JIT_SOLD_PRODUCT


export const GET_SUPPLEMENTAL_ORDERS = process.env.REACT_APP_GET_SUPPLEMENTAL_ORDERS
export const GET_SUPPLEMENTAL_ORDERS_PRODUCTS = process.env.REACT_APP_GET_SUPPLEMENTAL_ORDERS_PRODUCTS
export const CREATE_SUPPLEMENTAL_ORDERS = process.env.REACT_APP_CREATE_SUPPLEMENTAL_ORDERS
export const UPDATE_SUPPLEMENTAL_ORDERS = process.env.REACT_APP_UPDATE_SUPPLEMENTAL_ORDERS
export const DELETE_SUPPLEMENTAL_ORDERS = process.env.REACT_APP_DELETE_SUPPLEMENTAL_ORDERS

export const INVENTORY_PRODUCTS_URL = process.env.REACT_APP_INVENTORY_PRODUCTS_URL;
export const GET_INVENTORY_PRODUCT_BY_ID = process.env.REACT_APP_GET_INVENTORY_PRODUCT_BY_ID
export const IMPORT_INVENTORY_CSV_FILE = process.env.REACT_APP_IMPORT_INVENTORY_PRODUCTS_CSV_FILE;
export const DOWNLOAD_INVENTORY_CSV_FILE = process.env.REACT_APP_DOWNLOAD_INVENTORY_PRODUCTS_CSV_FILE;

export const JIT_PRODUCTS_URL = process.env.REACT_APP_JIT_PRODUCTS_URL;
export const GET_JIT_PRODUCT_BY_ID = process.env.REACT_APP_GET_JIT_PRODUCT_BY_ID
export const IMPORT_JIT_CSV_FILE = process.env.REACT_APP_IMPORT_JIT_PRODUCTS_CSV_FILE;
export const DOWNLOAD_JIT_CSV_FILE = process.env.REACT_APP_DOWNLOAD_JIT_PRODUCTS_CSV_FILE;
export const INVENTORY_AND_JIT_PRODUCTS_URL = process.env.REACT_APP_INVENTORY_AND_JIT_PRODUCTS_URL;
export const INVENTORY_AND_JIT_PRODUCTS_NOT_ASSIGNED_PRIMARY_SLOT = process.env.REACT_APP_INVENTORY_AND_JIT_PRODUCTS_NOT_ASSIGNED_PRIMARY_SLOT

export const GET_STATES_URL = process.env.REACT_APP_GET_STATES_URL;
export const GET_TIME_ZONES_URL = process.env.REACT_APP_GET_TIME_ZONES_URL;

export const GET_SAS_TOKEN = process.env.REACT_APP_GET_SAS_TOKEN;

export const MEASUREMENT_UNIT = process.env.REACT_APP_MEASUREMENT_UNIT;
export const HOLDING_ZONES = process.env.REACT_APP_HOLDING_ZONES;
export const GET_BARCODE_TYPES = process.env.REACT_APP_GET_BARCODE_TYPES;
export const BARCODE_TYPES_ENR = process.env.REACT_APP_BARCODE_TYPES_ENR;
export const CASE_TYPES = process.env.REACT_APP_CASE_TYPES;
export const DELIVERY_OVERRIDE_TYPES = process.env.REACT_APP_DELIVERY_OVERRIDE_TYPES;
export const WAREHOUSE_ROUTE_TYPES = process.env.REACT_APP_WAREHOUSE_ROUTE_TYPES;
export const USER_REGISTRATION_ROLE_SCOPE_TYPES = process.env.REACT_APP_USER_REGISTRATION_ROLE_SCOPE_TYPES
export const PRICING_OFFSET_TYPES = process.env.REACT_APP_PRICING_OFFSET_TYPES
export const BAY_WIDTH_TYPES = process.env.REACT_APP_BAY_WIDTH_TYPES
export const SLOT_WIDTH_TYPES = process.env.REACT_APP_SLOT_WIDTH_TYPES
export const DELIVERY_ORDER_STATES = process.env.REACT_APP_DELIVERY_ORDER_STATES
export const TYPE_OF_ORDERS = process.env.REACT_APP_TYPE_OF_ORDERS
export const TYPE_OF_PICKS = process.env.REACT_APP_TYPE_OF_PICKS
export const GET_SOURCING_TYPES = process.env.REACT_APP_SOURCING_TYPES
export const GRANULARITY_OF_PICK_TYPES = process.env.REACT_APP_GRANULARITY_OF_PICK_TYPES
export const INVENTORY_OR_JIT_PRODUCT_LOCATION_IN_SLOT_TYPES = process.env.REACT_APP_INVENTORY_OR_JIT_PRODUCT_LOCATION_IN_SLOT_TYPES
export const TYPE_OF_INTER_WAREHOUSE_ORDERS = process.env.REACT_APP_TYPE_OF_INTER_WAREHOUSE_ORDERS
export const DELIVERY_ROUTE_STATES = process.env.REACT_APP_DELIVERY_ROUTE_STATES
export const ORDERING_STATUS_TYPES = process.env.REACT_APP_ORDERING_STATUS_TYPES
export const REFERENCE_COLUMN_INVOICE_TYPES = process.env.REACT_APP_REFERENCE_COLUMN_INVOICE_TYPES

export const FILE_URL = process.env.REACT_APP_FILE_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const MAIN_IMAGE_URL = process.env.REACT_APP_MAIN_IMAGE_URL;
export const PROFILE_IMAGE_URL = process.env.REACT_APP_PROFILE_IMAGE_URL

export const GET_ZONES = process.env.REACT_APP_GET_ZONES;
export const POST_ZONES = process.env.REACT_APP_POST_ZONES
export const DELETE_ZONES = process.env.REACT_APP_DELETE_ZONES;
export const UPDATE_ZONE_SEQUENCE = process.env.REACT_APP_UPDATE_ZONE_SEQUENCE_FOR_PICKING
export const DOWNLOAD_ALL_SLOTS_IN_ZONE_CSV = process.env.REACT_APP_DOWNLOAD_ALL_SLOTS_IN_ZONE_CSV
export const IMPORT_SLOTS_IN_ZONE_CSV = process.env.REACT_APP_IMPORT_SLOTS_IN_ZONE_CSV

export const GET_AISLES = process.env.REACT_APP_GET_AISLES
export const POST_AISLES = process.env.REACT_APP_POST_AISLES
export const DELETE_AISLES = process.env.REACT_APP_DELETE_AISLES

export const GET_BAYS = process.env.REACT_APP_GET_BAYS;
export const POST_BAYS = process.env.REACT_APP_POST_BAYS
export const BAY_SEQUENCE_FOR_PICKING = process.env.REACT_APP_BAY_SEQUENCE_FOR_PICKING
export const UPDATE_BAY_SEQUENCE_FOR_PICKING = process.env.REACT_APP_UPDATE_BAY_SEQUENCE_FOR_PICKING
export const DELETE_BAYS = process.env.REACT_APP_DELETE_BAYS;

export const GET_SHELVES = process.env.REACT_APP_GET_SHELVES;
export const POST_SHELVES = process.env.REACT_APP_POST_SHELVES
export const GET_SHELVES_BY_SHELF_ID = process.env.REACT_APP_GET_SHELVES_BY_SHELF_ID
export const DELETE_SHELVES = process.env.REACT_APP_DELETE_SHELVES;

export const GET_SLOTS = process.env.REACT_APP_GET_SLOTS;
export const AVAILABLE_WIDTHS_FOR_SLOT_ADDITION = process.env.REACT_APP_AVAILABLE_WIDTHS_FOR_SLOT_ADDITION
export const GET_AVAILABLE_WIDTHS_FOR_SLOT_UPDATION = process.env.REACT_APP_GET_AVAILABLE_WIDTHS_FOR_SLOT_UPDATION
export const AVAILABLE_BOTTOM_SLOTS_FOR_STACKED_SLOT_ADDITION = process.env.REACT_APP_AVAILABLE_BOTTOM_SLOTS_FOR_STACKED_SLOT_ADDITION
export const SLOT_SEQUENCE_FOR_PICKING = process.env.REACT_APP_SLOT_SEQUENCE_FOR_PICKING
export const SLOT_PICKING_SEQUENCE_FOR_PICTORIAL_VIEW = process.env.REACT_APP_SLOT_PICKING_SEQUENCE_FOR_PICTORIAL_VIEW
export const POST_BOTTOM_SLOTS = process.env.REACT_APP_POST_BOTTOM_SLOTS;
export const POST_STACKED_SLOTS = process.env.REACT_APP_POST_STACKED_SLOTS;
export const UPDATE_BOTTOM_SLOT = process.env.REACT_APP_UPDATE_BOTTOM_SLOT
export const UPDATE_STACKED_SLOT = process.env.REACT_APP_UPDATE_STACKED_SLOT
export const CLEAR_NON_PRIMARY_SLOTS = process.env.REACT_APP_CLEAR_NON_PRIMARY_SLOTS
export const UPDATE_SLOT_SEQUENCE_FOR_PICKING = process.env.REACT_APP_UPDATE_SLOT_SEQUENCE_FOR_PICKING
export const DELETE_SLOTS = process.env.REACT_APP_DELETE_SLOTS;





export const STANDING_ORDER_URL = process.env.REACT_APP_STANDING_ORDER_URL
export const GET_STANDING_ORDER_WITH_CUTOFF_DATA_FOR_DATE = process.env.REACT_APP_GET_STANDING_ORDER_WITH_CUTOFF_DATA_FOR_DATE
export const GET_PAUSE_ENTRIES_STANDING_ORDER = process.env.REACT_APP_GET_PAUSE_ENTRIES_STANDING_ORDER
export const UPDATE_PAUSE_ENTRIES_STANDING_ORDER = process.env.REACT_APP_UPDATE_PAUSE_ENTRIES_STANDING_ORDER

export const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const GOOGLE_AUTOCOMPLETE = process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API;
export const GOOGLE_MAPS = process.env.REACT_APP_GOOGLE_MAPS_API;

export const INVALID = 0;
export const ACTIVE = 1;
export const ARCHIVED = 2;

