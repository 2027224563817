import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../utils/appInsights';

import './routes.css'
import { CustomerProtectedRoute, ProtectedRoute } from './protectedRoutes'
import { NavigationBar, Footer, UnAuthorizedRoutes, AuthorizedRoutes, CustomerAuthorizedRoutes, NotFoundPage } from './importRoutes'


const Routes = () => {
    return (
        <div>
            <Router>
                <Suspense fallback={<div>Page is Loading...</div>}>
                    <NavigationBar />
                    <AppInsightsErrorBoundary onError={() =>
                        <div id="div-container" className="routes-header">
                            <h4>Oops! We hit an error processing your request.<br />The error has been logged and our support team will be notified based on the severity of the problem.<br />Please <a href='/' onClick={()=> window.location.reload()}>click here</a> to refresh the page.</h4>
                        </div>
                    } appInsights={reactPlugin}>
                        <Switch>
                            {UnAuthorizedRoutes.map(({ path, Component }, ind) => (
                                <Route key={ind} exact path={path} component={Component} />
                            ))}
                            {AuthorizedRoutes.map(({ path, Component, allowedRoles, allAccess }, ind) => (
                                <ProtectedRoute key={ind} exact path={path} component={Component} allowedRoles={allowedRoles} allAccess={allAccess} />
                            ))}
                            {CustomerAuthorizedRoutes.map(({ path, Component, exact, allowedRoles, allAccess }, ind) => (
                                <CustomerProtectedRoute key={ind} exact={exact} path={path} component={Component} allowedRoles={allowedRoles} allAccess={allAccess} />
                            ))}
                            <ProtectedRoute path="*" component={NotFoundPage} />
                        </Switch>
                    </AppInsightsErrorBoundary>
                    <Footer />
                </Suspense>
            </Router>
        </div>
    )
}

export default Routes