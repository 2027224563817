import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { BASE_URL } from './app/utils/constant';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import BrownLLSubRegularSubset from './Fonts/BrownLLSub-RegularSubset.woff';

let theme = createTheme({
  typography: {
    fontFamily: 'BrownLLSub !important',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'BrownLLSub';
          font-style: normal;
          font-display: swap;
          src: local('Raleway'), local('BrownLLSub-RegularSubset'), url(${BrownLLSubRegularSubset}) format('woff');
        }
      `,
    },
  },
});

theme = responsiveFontSizes(theme);

axios.defaults.baseURL = BASE_URL;
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
