import React from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../utils/auth";

export const ProtectedRoute = ({ component: Component, allowedRoles, allAccess, ...rest }) => {

    const pathname = rest.location.pathname;
    const queryString = rest.location.search;
    const IS_OPS_MANAGER = JSON.parse(localStorage.getItem('isOpsManagerUser'));
    const ROLES = JSON.parse(localStorage.getItem('userRolesForLocation'));

    return (
        <Route {...rest}
            render={(props) => Auth.isAuthenticated() && IS_OPS_MANAGER && ((typeof allAccess === "boolean" && allAccess === true) ||
                (allowedRoles?.findIndex((role) => ROLES?.includes(role)) !== -1)) ? ( <Component {...props} /> )
                : ( <Redirect to={{ pathname: "/login", state: pathname, search: queryString }} /> )
            }
        />
    );
};

export const CustomerProtectedRoute = ({ component: Component, allowedRoles, allAccess, ...rest }) => {

    const pathname = rest.location.pathname;
    const queryString = rest.location.search;
    const ROLES = JSON.parse(localStorage.getItem('userRolesForLocation'));

    return (
        <Route {...rest}
            render={(props) => Auth.isAuthenticated() && ((typeof allAccess === "boolean" && allAccess === true) ||
                    (allowedRoles?.findIndex((role) => ROLES?.includes(role)) !== -1)) ? (<Component {...props} />)
                : (<Redirect to={{ pathname: "/login", state: pathname, search: queryString }} />)
            }
        />
    );
};
