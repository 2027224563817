import React from 'react'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './app/utils/appInsights';
import {LATEST_COMMIT_ID} from './app/utils/constant.js';
import "./App.css";
import Routes from "./app/Routes/routes";

function App() {
  return (
    <div className="App" data-commit-id={LATEST_COMMIT_ID}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <Routes />
      </AppInsightsContext.Provider>
    </div>
  );
}

export default App;
